/** @jsxImportSource theme-ui */
import AnnouncementSection from '@/components/HomePage/AnnouncementSection'
import OverviewSection from '@/components/HomePage/OverviewSection'
import ProfileSection from '@/components/HomePage/ProfileSection'
import Spacer from '@/components/Spacer'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { Fragment } from 'react'
import { Container } from 'theme-ui'

import type { NextPage } from 'next'
import MyReviewBanner from '@/components/MyReviewBanner'
const BaseAdSlot = dynamic(() => import('@/components/BaseAdSlot'), { ssr: false })

/**
 * @name 首頁
 */
const Home: NextPage = () => {
  return (
    <Fragment>
      <Head>
        <title>KadoKado創作中心 </title>
      </Head>
      <Container px={3} pt={4} pb={5}>
        {/* 作者資訊 */}
        <ProfileSection />
        <Spacer height={4} />
        {/* 本月總覽 */}
        <OverviewSection />
        <Spacer height={4} />
        {/* 個人年度回顧 */}
        <MyReviewBanner />
        {/* 創作者獎勵申請 */}
        {/* <UgcPromotionBanner /> */}
        {/* Ad 內廣版位 */}
        {/* <BaseAdSlot
          adUnitCode="creator_annoucement"
          slotId="div-gpt-ad-1651830513531-0"
          adSizes={[
            [970, 90],
            [300, 250],
          ]}
        /> */}
        {/* 創作中心公告 */}
        <AnnouncementSection />
      </Container>
    </Fragment>
  )
}

export default Home
