/** @jsxImportSource theme-ui */
import Spacer from '@/components/Spacer'
import { getKadoDomain, pxToRem } from '@/utils'
import Link from 'next/link'
import { Fragment } from 'react'
import { Box, Image } from 'theme-ui'

const ASSET_URL = '/static/images/review/'

const MyReviewBanner = () => {
  return (
    <Fragment>
      <Link
        href="https://event.kadokado.com.tw/2024/review/index.html"
        target="_blank"
        rel="noopener noreferrer"
        sx={{ display: 'block', px: [pxToRem(16), 0] }}
      >
        <Box
          sx={{
            position: 'relative',
            width: 'full',
            aspectRatio: '6 / 5',
            display: ['block', 'none'],
          }}
        >
          <Image
            src={`${ASSET_URL}banner_mobile.svg`}
            alt="my review banner"
            sx={{ width: 'full' }}
          />
        </Box>
        <Box
          sx={{
            position: 'relative',
            width: 'full',
            aspectRatio: '97 / 9',
            display: ['none', 'block'],
          }}
        >
          <Image src={`${ASSET_URL}banner_pc.svg`} alt="my review banner" sx={{ width: 'full' }} />
        </Box>
      </Link>
      <Spacer height={4} />
    </Fragment>
  )
}

export default MyReviewBanner
